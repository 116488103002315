import {
    Theme,
    ThemeProvider,
    StyledEngineProvider,
    responsiveFontSizes,
    createTheme,
    useMediaQuery,
} from '@mui/material';
import { ReactNode, useContext } from 'react';
import { GlobalContext } from '../../context/stores/GlobalContext';

export enum THEME {
    LIGHT,
    DARK,
    AUTO,
}

export interface ThemeContainer {
    darkTheme: Theme;
    lightTheme: Theme;
}

export function AutoThemeProvider(props: AutoThemeProviderProps) {
    const { themeContainer, children } = props;

    const [{ preferences }] = useContext(GlobalContext);
    const prefersDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');

    // We'll parse the user's current theme preferences, either manually set or 'auto', defaulting to 'LIGHT' if not
    // specified or supported.
    const useDark = preferences.theme === THEME.DARK || (preferences.theme === THEME.AUTO && prefersDarkTheme);
    const userTheme: Theme = responsiveFontSizes(
        createTheme(useDark ? themeContainer.darkTheme : themeContainer.lightTheme),
    );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={userTheme}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
}

interface AutoThemeProviderProps {
    children?: ReactNode;
    themeContainer: ThemeContainer;
}
