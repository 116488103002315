import { ReactNode } from 'react';
import { createTheme, PaletteOptions, ThemeOptions, alpha } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { ContainerTypeMap } from '@mui/material';
import {
    ColorCaution,
    ColorConfirm,
    NeutralGrey,
    ColorSoftError,
    ColorVRBlue,
    ColorVRBlueLight,
    ColorVRGrey,
} from './Colors';
import { AutoThemeProvider, ThemeContainer } from './AutoThemeProvider';

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
        confirm: Palette['primary'];
        caution: Palette['primary'];
        softError: Palette['primary'];
    }
    interface PaletteOptions {
        neutral: PaletteOptions['primary'];
        confirm: PaletteOptions['primary'];
        caution: PaletteOptions['primary'];
        softError: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}

export const CONTAINER_WIDTH: ContainerTypeMap['props']['maxWidth'] = false;

const VRPalette: PaletteOptions = {
    primary: ColorVRBlue,
    secondary: ColorVRGrey,
    confirm: ColorConfirm,
    caution: ColorCaution,
    softError: ColorSoftError,
    neutral: NeutralGrey,
};

export const ThemeBase: ThemeOptions = {
    palette: VRPalette,
    typography: {
        h1: {
            fontSize: '3rem',
        },
        h2: {
            fontSize: '2.5rem',
        },
        h3: {
            fontSize: '2.0rem',
        },
        h4: {
            fontSize: '1.8rem',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiFormControl: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiButton: {
            defaultProps: {
                color: 'neutral',
            },
            /**
             * With the default button variant (`text`), MUI uses the `main`
             * color for the text instead of the contrastText value. This normally works
             * well, but our backported `default` button colorscheme specifically doesn't
             * work with this. There are also some other complication due to
             * `default` previously being a special case, so this patches the CSS here to
             * use the correct color & correct the other issues.
             */
            styleOverrides: {
                root: (props) => {
                    if (props.ownerState.color === 'neutral') {
                        const palette = props.theme.palette;
                        const darkmode = palette.mode === 'dark';
                        const variant = props.ownerState.variant;

                        const contrastBase = darkmode ? '#fff' : '#000';
                        const base = {
                            color: palette.neutral.contrastText,
                            ':hover': {
                                backgroundColor: alpha(contrastBase, 0.04),
                                borderColor: alpha(contrastBase, 0.23),
                            },
                        };

                        if (variant === 'text') {
                            return base;
                        } else if (variant === 'outlined') {
                            return { ...base, borderColor: alpha(contrastBase, 0.23) };
                        } else if (variant === 'contained' && darkmode) {
                            return { color: '#000' };
                        }
                    }
                },
            },
        },
    },
};

export const DarkThemeBackground = {
    background: {
        default: '#303030',
        paper: '#424242',
    },
};

export const LightTheme = deepmerge(ThemeBase, {
    palette: {
        mode: 'light',
    },
});

export const DarkTheme = deepmerge(ThemeBase, {
    palette: {
        mode: 'dark',
        primary: ColorVRBlueLight,
        neutral: {
            contrastText: '#fff',
        },
        ...DarkThemeBackground,
    },
});

export const BaseThemeContainer = { darkTheme: DarkTheme, lightTheme: LightTheme };

export const ViewrailThemeContainer: ThemeContainer = {
    darkTheme: createTheme(DarkTheme),
    lightTheme: createTheme(LightTheme),
};

export function VRDTheme(props: { children?: ReactNode }) {
    return <AutoThemeProvider themeContainer={ViewrailThemeContainer}>{props.children}</AutoThemeProvider>;
}
