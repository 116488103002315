import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

export function usePersistValue<Value = any>(
    persistAs: string,
    defaultValue: Value,
    options?: {
        storeValue?: (val: Value) => string;
        retrieveValue?: (raw: string) => Value;
    },
): PersistValueReturnType<Value> {
    const opts = useMemo(() => ({ storeValue: JSON.stringify, retrieveValue: JSON.parse, ...options }), [options]);
    const storedValue = localStorage.getItem(persistAs);
    const parsedValue: Value | null = storedValue ? opts.retrieveValue(storedValue) : null;

    const [value, setValue] = useState<Value>(parsedValue !== null ? parsedValue : defaultValue);

    useEffect(() => {
        localStorage.setItem(persistAs, opts.storeValue(value));
    }, [persistAs, opts, value]);

    return [value, setValue];
}

export type PersistValueReturnType<Value> = [Value, Dispatch<SetStateAction<Value>>];
