import { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Check from '@mui/icons-material/Check';
import GetApp from '@mui/icons-material/GetApp';
import Sync from '@mui/icons-material/Sync';
import { useSnackbar } from 'notistack';
import { useGqlClient } from '../../GraphQL/Client';
import { usePersistValue } from './PersistValue';

export type CacheManifest = {
    [name: string]: Date;
};

/**
 * Parses the raw JSON representation of a CacheManifest into a proper object.
 */
export function parseCacheManifest(value: string): CacheManifest {
    const convertEntry = ([id, expiry]: [string, string]): [string, Date] => [id, new Date(expiry)];

    const parsed = Object.entries<string>(JSON.parse(value)).map(convertEntry);
    return Object.fromEntries(parsed);
}

export function useCacheManifest() {
    const [cache, setCache] = useState<{ [id: string]: any }>({});
    const [manifest, setManifest] = usePersistValue<CacheManifest>(
        'cache-manifest',
        {},
        { retrieveValue: parseCacheManifest },
    );
    const { persistor } = useGqlClient();

    // immediately save cache on manifest change
    useEffect(() => {
        let effectCancelled = false;

        const update = async () => {
            if (effectCancelled) return;

            await persistor?.persist();
            const data = ((await persistor?.storage.read()) as string) ?? '{}';
            setCache(JSON.parse(data));
        };
        update();

        return () => {
            effectCancelled = true;
        };
    }, [persistor, manifest]);

    const isExpired = ([_, expiry]: [string, Date]) => expiry && new Date() > expiry;

    // recalculate the manifest (trim expired items) if any items are found to be expired
    if (Object.entries(manifest).some(isExpired)) {
        const filtered = Object.fromEntries(Object.entries(manifest).filter((value) => !isExpired(value)));
        setManifest(filtered);
    }

    // convenience methods
    function cacheItem(id: string, days: number = 7) {
        setManifest({ ...manifest, [id]: moment().add(days, 'days').toDate() });
    }

    function uncacheItem(id: string) {
        setManifest(Object.fromEntries(Object.entries(manifest).filter(([itemId, _]) => itemId !== id)));
    }

    function purgeManifest() {
        setManifest({});
    }

    function itemInManifest(id: string) {
        return Object.keys(manifest).includes(id);
    }

    function itemIsSyncedDependency(id: string) {
        return Object.keys(cache).includes(id) && !itemInManifest(id);
    }

    function itemIsSynced(id: string) {
        return Object.keys(cache).includes(id);
    }

    function cacheTimeRemaining(id: string) {
        const now = moment();
        return moment.duration(now.diff(manifest[id]));
    }

    return {
        cache,
        manifest,
        cacheItem,
        uncacheItem,
        purgeManifest,
        itemInManifest,
        itemIsSynced,
        itemIsSyncedDependency,
        cacheTimeRemaining,
    };
}

const useStyles = makeStyles({
    button: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
});

interface CacheItemButtonProps {
    item: { id: string; __typename?: string },
    refetch?: () => void,
}

export function CacheItemButton(props: CacheItemButtonProps) {
    const { item, refetch = () => { } } = props;
    const classes = useStyles();
    const { client } = useGqlClient();
    const { cache, manifest, cacheItem, itemInManifest, itemIsSyncedDependency } = useCacheManifest();
    const { enqueueSnackbar } = useSnackbar();

    const cacheId = client?.cache.identify(item);
    const typename = item.__typename ?? 'item';

    function cacheAndRefetch() {
        if (cacheId) {
            cacheItem(cacheId);
            refetch();
            enqueueSnackbar('Latest changes were synced!', { variant: 'success' });
        }
    }

    // length check is to prevent button flickering when cache is still loading.
    // when loaded, it should be guaranteed to be at least 1 long (due to `ROOT_QUERY`)
    if (!cacheId || !Object.keys(cache).length) {
        return null;
    } else
        return (
            <>
                {itemIsSyncedDependency(cacheId) ? (
                    <Tooltip title={`This ${typename} is saved automatically due to being needed by something else.`}>
                        <Button
                            startIcon={<Check />}
                            component="div"
                            variant="contained"
                            disabled
                            className={classes.button}
                        >
                            Synced
                        </Button>
                    </Tooltip>
                ) : itemInManifest(cacheId) ? (
                    <Tooltip title={`This ${typename} was last synced to your device on ${manifest[cacheId]}.`}>
                        <Button
                            onClick={cacheAndRefetch}
                            startIcon={<Sync />}
                            variant="contained"
                            color="secondary"
                        >
                            Resync to device
                        </Button>
                    </Tooltip>
                ) : (
                    <Tooltip title={`Save this ${typename} to your device for 7 days.`}>
                        <Button
                            onClick={cacheAndRefetch}
                            startIcon={<GetApp />}
                            variant="contained"
                        >
                            Sync to device
                        </Button>
                    </Tooltip>
                )}
            </>
        );
}

export default useCacheManifest;
