import { useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';

const META_NAME = 'theme-color';
const APPLE_META_NAME = 'apple-mobile-web-app-status-bar-style';

/**
 * Automatically change the color of the device status bar to match the app
 * topbar, when this app is installed as a standalone PWA.
 */
function ThemeColorHelper() {
    const theme = useTheme();
    const color = theme.palette.primary.main;
    // Apple is weird, and sometimes this still doesn't work.
    const apple_color = 'black-translucent';

    return (
        <Helmet>
            <meta name={META_NAME} content={color} />
            <meta name={APPLE_META_NAME} content={apple_color} />
        </Helmet>
    );
}

export default ThemeColorHelper;
