import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingScreen from './Global/Components/LoadingScreen';
import ThemeColorHelper from './Global/Components/ThemeColorHelper';
import { VRDTheme } from './Global/Theme/Theme';
import { GlobalContextProvider } from './context/stores/GlobalContext';
import GraphQLProvider from './GraphQL/GraphQLProvider';

const Customer = lazy(() => import('./Customer/Customer'));
const Admin = lazy(() => import('./Admin/Admin'));

function App() {
    return (
        <GlobalContextProvider>
            <VRDTheme>
                <CssBaseline />
                <ThemeColorHelper />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <SnackbarProvider>
                        <GraphQLProvider>
                            <Suspense fallback={<LoadingScreen />}>
                                <BrowserRouter>
                                    <Routes>
                                        <Route path="admin/*" element={<Admin />} />
                                        <Route path="*" element={<Customer />} />
                                    </Routes>
                                </BrowserRouter>
                            </Suspense>
                        </GraphQLProvider>
                    </SnackbarProvider>
                </LocalizationProvider>
            </VRDTheme>
        </GlobalContextProvider>
    );
}

export default App;
