import { CircularProgress, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    loadingUser: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
    },
    loadingText: {
        marginLeft: theme.spacing(3),
        ...theme.typography.h1,
    },
}));

function LoadingScreen() {
    const classes = useStyles();

    return (
        <div className={classes.loadingUser}>
            <CircularProgress variant="indeterminate" />
            <span className={classes.loadingText}>Loading</span>
        </div>
    );
}

export default LoadingScreen;
