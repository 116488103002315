import { useCallback, useContext, useEffect, useState } from 'react';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient, FetchPolicy } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { IonicStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import { Storage } from '@ionic/storage';
import { GlobalContext } from '../context/stores/GlobalContext';
import { LoginAction } from '../context/constants/action-types';
import { useSnackbar } from 'notistack';
import { makeEndpointUrl } from './GraphQLClientHelpers';
import { getEnv } from '../Global/Helpers/Env';
import { PersistLink, persistenceMapper } from './PersistLink';
import { createCacheFallbackLink, paginatedCacheRead, singleCacheRead } from './CacheFallbackLink';

export const HideErrorContext = {
    hideErrors: true,
};

export function useGqlClient() {
    const [{ user: { token = null } = {} }, dispatch] = useContext(GlobalContext);
    const [client, setClient] = useState<null | ApolloClient<any>>(null);
    const [clientToken, setClientToken] = useState<string | null>(null);
    const [persistor, setPersistor] = useState<CachePersistor<NormalizedCacheObject> | null>(null);
    const revokeCallback = useCallback(revoke, [dispatch]);
    const { enqueueSnackbar } = useSnackbar();

    function revoke() {
        dispatch({
            type: LoginAction.USER_AUTH_REVOKED,
            reason: 'Invalid token',
        });
    }

    const clearCache = useCallback(() => {
        persistor?.purge();
    }, [persistor]);

    useEffect(() => {
        // simple check to allow cancelling async effects
        let effectCancelled = false;

        async function clientHook() {
            const store = new Storage();
            const cache = new InMemoryCache();
            /**
             * Use custom field resolvers to locally fill from cache if no
             * network available (see typePolicies above)
             *
             * Hooks using this also need to be passed `context: { __skipErrorAccordingCache__: true }` to be able to fallback to the cache.
             *
             * In order for this to work properly, the attributes of the search
             * fragment MUST be a complete subset of the fragment that gets cached.
             * Otherwise, Apollo considers it a cache miss and doesn't fulfill the request
             */

            const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
                // Cancel any error handling if the effect was invalidated.
                // If we don't, it causes a race condition with authentication
                if (effectCancelled) {
                    return;
                }

                // No longer authorized, logout user
                if (graphQLErrors) {
                    // User is unauthenticated
                    const authError = graphQLErrors.find((error) => error.message === 'Unauthenticated.');
                    if (authError) {
                        revokeCallback();
                    }

                    // User doesn't have the permissions for the previous request
                    const permissionError = graphQLErrors.find(
                        (error) => error.message.indexOf('not authorized') !== -1,
                    );
                    if (permissionError) {
                        enqueueSnackbar('Request failed - invalid user permissions.', {
                            variant: 'error',
                        });
                    }

                    // Unless set to 'hideErrors' in context, show readable GraphQL Errors in snackbar
                    if (!operation.getContext().hideErrors && graphQLErrors) {
                        const errors = graphQLErrors.map((error) => error.extensions?.reason ?? error.message);

                        errors.map((error) =>
                            enqueueSnackbar(error, {
                                variant: 'error',
                                preventDuplicate: true,
                            }),
                        );

                        /**
                         * If we're handling errors automatically, remove the errors from the response so we don't
                         * get uncaught exceptions
                         */
                        delete response?.errors;

                        /* Example to hide errors if handling separately:
                            useQuery({
                                context: HideErrorContext
                            })
                         */
                    }

                    // Log errors to console. TODO: Log errors with service?
                    graphQLErrors.forEach(({ message, locations, path }) =>
                        // eslint-disable-next-line no-console
                        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
                    );
                }

                // eslint-disable-next-line no-console
                if (networkError) console.log(`[Network error]: ${networkError}`);
            });

            // cache persistence
            await store.create();
            const storage = new IonicStorageWrapper(store);
            const newPersistor = new CachePersistor({
                cache,
                storage,
                debug: false,
                trigger: 'write',
                persistenceMapper,
            });
            await newPersistor.restore();
            setPersistor(newPersistor);

            const uploadLink = createUploadLink({
                uri: makeEndpointUrl(getEnv('GRAPHQL_HOST') || '/graphql/'),
                headers: token
                    ? {
                          Authorization: `Bearer ${token}`,
                      }
                    : {},
            });

            const cacheFallbackLink = createCacheFallbackLink({
                handlers: {
                    single: singleCacheRead,
                    paginated: paginatedCacheRead,
                },
                default: 'single',
            });

            const client = new ApolloClient({
                link: ApolloLink.from([
                    errorLink,
                    new PersistLink(),
                    //@ts-expect-error
                    cacheFallbackLink,
                    uploadLink,
                ]),
                cache,
                defaultOptions: {
                    query: {
                        fetchPolicy: 'cache-and-network' as FetchPolicy,
                    },
                },
                connectToDevTools: true,
            });

            if (!effectCancelled) {
                setClient(client);
                setClientToken(token);
            }
        }

        clientHook();

        return () => {
            effectCancelled = true;
        };
    }, [token, revokeCallback, enqueueSnackbar]);

    return {
        client,
        clientToken,
        clearCache,
        persistor: persistor?.persistor,
    };
}
