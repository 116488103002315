import { Maybe } from 'graphql/jsutils/Maybe';

interface ENV {
    API_HOST: Maybe<string>;
    GRAPHQL_HOST: Maybe<string>;
    DOMAIN: Maybe<string>;
}

const ENVIRONMENT = (window as any)?._ENV ?? {};

/**
 * Queries runtime environment variables for the selected 'key'. Should be used in place of `process.env.x` unless in
 * specific circumstances.
 *
 * @param key
 */
export function getEnv(key: keyof ENV): Maybe<string> {
    return ENVIRONMENT[key];
}
