import { InMemoryCache } from '@apollo/client/cache';
import { DocumentNode, Operation, Cache } from '@apollo/client';
import { NetworkErrorLink } from 'apollo-link-network-error';
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql';

export type ReadField = (field: string, obj: Record<string, any>) => any;
export type FilterCacheFunc = (obj: any, helpers: FilterCacheFuncHelpers) => any;

/**
 * Accept an Operation, and return data from the cache to fulfill it.
 */
export type OperationHandler = (operation: Operation) => any;

interface FilterCacheFuncHelpers {
    cache: InMemoryCache,
    operation: Operation,
    variables: Record<string, any>,
    readField: ReadField,
}

export function makeHelpers(cache: InMemoryCache, operation: Operation) {
    const readField: ReadField = (field, obj) => {
        if (obj.__ref) {
            const data = cache.extract();
            return data[obj.__ref]?.[field];
        } else {
            return obj?.[field];
        }
    }
    const helpers: FilterCacheFuncHelpers = {
        cache,
        operation,
        variables: operation.variables,
        readField,
    };
    return helpers;
}

export interface CacheFallbackContext {
    filters: FilterCacheFunc[];
    fragment: DocumentNode;
    handler?: string | OperationHandler;
}

/**
 * Get the name of the type a fragment extends.
 */
export function determineTypename(fragment: CacheFallbackContext['fragment']) {
    return (fragment?.definitions[0] as FragmentDefinitionNode).typeCondition.name.value;
}

/**
 * Get the name of the query contained in a given DocumentNode.
 */
export function determineQueryName(query: DocumentNode) {
    const defs = query.definitions.filter(def => def.kind === 'OperationDefinition')[0] as OperationDefinitionNode;
    const selection = defs?.selectionSet.selections[0]
    return (selection as any)?.name.value;
}

export function getOperationContext(operation: Operation) {
    const context = operation.getContext() as { cache: InMemoryCache, cacheFallbackOptions: CacheFallbackContext };
    return context;
}

export function getAllIdsOfType(cache: InMemoryCache, typename: string) {
    const ids = Object.entries(cache.extract()).filter(
        ([_, item]) => item?.__typename === typename,
    ).map(([id, _]) => id);
    return ids;
}
export function getAllOfType(cache: InMemoryCache, typename: string, readFragmentOptions: Omit<Cache.ReadFragmentOptions<any, any>, 'id'>) {
    const ids = getAllIdsOfType(cache, typename);
    const items: any[] = ids.map((id) => cache.readFragment({ id, ...readFragmentOptions }));
    return items;
}

export function paginatedCacheRead(operation: Operation) {
    const { variables, query } = operation;
    const { cache, cacheFallbackOptions } = getOperationContext(operation);
    const { filters, fragment } = cacheFallbackOptions;

    // intelligently determine certain parts of the query
    const typename = determineTypename(fragment);
    const fallbackQueryName = typename.charAt(0).toLowerCase() + typename.slice(1) + 's';
    const queryName = determineQueryName(query) ?? fallbackQueryName;

    // get all objects in cache of the desired type
    const allItems = getAllOfType(cache, typename, cacheFallbackOptions);

    // filter
    let items = allItems;
    if (variables?.filter) {
        const helpers = makeHelpers(cache, operation)
        for (const filter of filters) {
            items = items.filter((obj) => filter(obj, helpers));
        }
    }

    // stub pagination
    const data = {
        [queryName]: {
            __typename: `${typename}Paginator`,
            paginatorInfo: {
                __typename: 'PaginatorInfo',
                count: items.length,
                currentPage: 1,
                lastPage: 1,
                total: items.length,
                perPage: items.length,
            },
            data: items,
        }
    }
    return data;
}

export function singleCacheRead(operation: Operation) {
    const { variables, query } = operation;
    const { cache, cacheFallbackOptions } = getOperationContext(operation);
    const { fragment } = cacheFallbackOptions;

    const typename = determineTypename(fragment);
    const fallbackQueryName = typename.charAt(0).toLowerCase() + typename.slice(1) + 's';
    const queryName = determineQueryName(query) ?? fallbackQueryName;

    return {
        [queryName]: cache.readFragment({ id: `${typename}:${variables.id}`, ...cacheFallbackOptions }),
    };
}

export function createCacheFallbackLink<H extends { [name: string]: OperationHandler }, K extends keyof H>(options: { handlers: H, default?: K }) {
    const { handlers } = options;
    const defaultHandler = options.default ?? Object.keys(handlers)[0];

    const link = new NetworkErrorLink(({ networkError, operation }) => {
        const options = operation.getContext().cacheFallbackOptions;

        const handler = options.handler instanceof Function ? options.handler : handlers[options.handler ?? defaultHandler];
        if (options && handler) {
            return handler(operation);
        } else {
            throw networkError;
        }
    });

    return link;
}

export default createCacheFallbackLink;
