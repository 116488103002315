import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

/* We need to use augmentColor() to autogenerate the missing palette components
 * for our custom colors, since MUI only does this automatically for the colors
 * they provide. However, augmentColor() is only available via a created theme,
 * so we create a dummy theme to get access to the method.
 */
const augment = createTheme().palette.augmentColor;

// VRD
export const ColorConfirm = augment({ color: { main: '#4caf50' } });
export const ColorCaution = augment({ color: { main: '#dfdf49' } });
export const ColorSoftError = augment({ color: { main: '#ff6978' } });

// Viewrail
export const ColorVRBlue = augment({ color: { main: '#0485a1' } });
export const ColorVRBlueLight = augment({ color: { main: '#74cee1' } });
export const ColorVRBlueGrey = augment({ color: { main: '#313131' } });
export const ColorVRGrey = augment({ color: { main: '#443e42' } });
export const ColorSecondary = augment({ color: { main: '#f50057' } });

// Misc
export const NeutralGrey = augment({
    color: {
        main: grey[300],
        dark: grey[400],
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
});
