import { produce } from 'immer';
import { LoginAction } from '../constants/action-types';
import { User } from '../../generated/graphql';

export interface UserState {
    isLoggedIn: boolean;
    token: string | null;
    redirect: boolean;
    reason: null | string;
    id?: string;
}

export interface AuthAction {
    type: LoginAction;
    token?: string;
    id?: string;
    reason?: string;
    user?: Pick<User, 'permissions' | 'name' | 'id'>;
}

export const initialUserState: UserState = {
    isLoggedIn: false,
    token: null,
    reason: null,
    redirect: false,
};

export const AuthReducer = (user: UserState = initialUserState, action: AuthAction) => {
    switch (action.type) {
        case LoginAction.USER_LOGIN_SUCCESS:
            return produce(user, (newUser: UserState) => {
                newUser.isLoggedIn = true;
                newUser.token = action.token ? action.token : null;
                newUser.redirect = true;
                newUser.reason = null;
                newUser.id = action.user?.id;
            });
        case LoginAction.USER_LOGOUT_SUCCESS:
            return produce(user, (newUser: UserState) => {
                newUser.isLoggedIn = false;
                newUser.token = null;
                newUser.redirect = false;
                newUser.id = undefined;
            });
        case LoginAction.USER_AUTH_REVOKED:
            return produce(user, (newUser: UserState) => {
                newUser.isLoggedIn = false;
                newUser.token = null;
                newUser.redirect = false;
                newUser.reason = action.reason ? action.reason : null;
                newUser.id = undefined;
            });
        default:
            return user;
    }
};
