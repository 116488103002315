export enum LoginAction {
    USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
    USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS',
    USER_AUTH_REVOKED = 'USER_AUTH_REVOKED',
}

// Preferences
export enum PreferenceActionEnum {
    PREF_TOGGLE_DRAWER_COLLAPSE = 'PREF_TOGGLE_DRAWER_COLLAPSE',
    PREF_TOGGLE_THEME = 'PREF_TOGGLE_THEME',
    PREF_TOGGLE_SCALE = 'PREF_TOGGLE_SCALE',
    PREF_TOGGLE_FULLSCREEN = 'PREF_TOGGLE_FULLSCREEN',
    PREF_SET_THEME = 'PREF_SET_THEME',
    PREF_SET_AUTOFOCUS = 'PREF_SET_AUTOFOCUS',
}
