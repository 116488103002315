import { PropsWithChildren } from 'react';
import { useGqlClient } from './Client';
import { ApolloProvider } from '@apollo/client';

function GraphQLProvider(props: PropsWithChildren<{}>) {
    const { client } = useGqlClient();

    // Wait until client is ready
    if (!client) {
        return null;
    }

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default GraphQLProvider;
