import ReactDOM from 'react-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/material-icons';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: () => window.dispatchEvent(new CustomEvent('update-available')),
});
