import { PreferenceActionEnum } from '../constants/action-types';
import { produce } from 'immer';
import { THEME } from '../../Global/Theme/ThemeSetting';

export interface PreferenceState {
    autofocus: boolean;
    drawerCollapsed: boolean;
    scaleActive: boolean;
    theme: THEME;
    fullscreenActive: boolean;
}

interface PreferenceActionDefaultType {
    type: PreferenceActionEnum;
}

interface PreferenceDefaultAction {
    type:
    | PreferenceActionEnum.PREF_TOGGLE_DRAWER_COLLAPSE
    | PreferenceActionEnum.PREF_TOGGLE_THEME
    | PreferenceActionEnum.PREF_TOGGLE_SCALE
    | PreferenceActionEnum.PREF_TOGGLE_FULLSCREEN;
}

interface PreferenceThemeAction extends PreferenceActionDefaultType {
    type: PreferenceActionEnum.PREF_SET_THEME;
    theme: THEME;
}

interface PreferenceAutofocusAction extends PreferenceActionDefaultType {
    type: PreferenceActionEnum.PREF_SET_AUTOFOCUS;
    autofocus: boolean;
}

export type PreferenceAction = PreferenceDefaultAction | PreferenceThemeAction | PreferenceAutofocusAction;

export const initialPreferenceState: PreferenceState = {
    autofocus: true,
    drawerCollapsed: false,
    scaleActive: true,
    theme: THEME.AUTO,
    fullscreenActive: false,
};

export default function PreferenceReducer(state: PreferenceState = initialPreferenceState, action: PreferenceAction) {
    switch (action.type) {
        case PreferenceActionEnum.PREF_TOGGLE_DRAWER_COLLAPSE:
            return produce(state, (newState: PreferenceState) => {
                newState.drawerCollapsed = !state.drawerCollapsed;
            });
        case PreferenceActionEnum.PREF_TOGGLE_SCALE:
            return produce(state, (newState: PreferenceState) => {
                newState.scaleActive = !state.scaleActive;
            });
        case PreferenceActionEnum.PREF_SET_THEME:
            return produce(state, (newState: PreferenceState) => {
                newState.theme = action.theme;
            });
        case PreferenceActionEnum.PREF_SET_AUTOFOCUS:
            return produce(state, (newState: PreferenceState) => {
                newState.autofocus = action.autofocus;
            });
        case PreferenceActionEnum.PREF_TOGGLE_FULLSCREEN:
            return produce(state, (newState: PreferenceState) => {
                newState.fullscreenActive = !state.fullscreenActive;
            });
        default:
            return state;
    }
}
