/**
 * Taken from https://github.com/TallerWebSolutions/apollo-cache-instorage
 */
import { visit } from 'graphql';
import { ApolloLink } from '@apollo/client';
import traverse from 'traverse';
import { parseCacheManifest } from '../Global/Helpers/useCacheManifest';

const extractPersistDirectivePaths = (originalQuery: any, directive: string = 'persist') => {
    const paths: any[] = [];
    const fragmentPaths: any = {};
    const fragmentPersistPaths: any = {};

    const query = visit(originalQuery, {
        FragmentSpread: (
            { name: { value: name } }: any,
            // ts complains about these not being used, however they're positional
            // parameters, so we can't remove them due to ancestors being needed.
            // @ts-ignore
            key: any,
            parent: any,
            path: any,
            ancestors: any,
        ): any => {
            const root = ancestors.find(
                ({ kind }: any) => kind === 'OperationDefinition' || kind === 'FragmentDefinition',
            );

            const rootKey = root.kind === 'FragmentDefinition' ? root.name.value : '$ROOT';

            const fieldPath = ancestors
                .filter(({ kind }: any) => kind === 'Field')
                .map(({ name: { value: name } }: any) => name);

            fragmentPaths[name] = [rootKey].concat(fieldPath);
        },
        Directive: (
            { name: { value: name } }: any,
            // ts complains about these not being used, however they're positional
            // parameters, so we can't remove them due to ancestors being needed.
            // @ts-ignore
            key: any,
            parent: any,
            path: any,
            ancestors: any,
        ): any => {
            if (name === directive) {
                const fieldPath = ancestors
                    .filter(({ kind }: any) => kind === 'Field')
                    .map(({ name: { value: name } }: any) => name);

                const fragmentDefinition = ancestors.find(({ kind }: any) => kind === 'FragmentDefinition');

                // If we are inside a fragment, we must save the reference.
                if (fragmentDefinition) {
                    fragmentPersistPaths[fragmentDefinition.name.value] = fieldPath;
                } else if (fieldPath.length) {
                    paths.push(fieldPath);
                }

                return null;
            }
        },
    });

    // In case there are any FragmentDefinition items, we need to combine paths.
    if (Object.keys(fragmentPersistPaths).length) {
        visit(originalQuery, {
            FragmentSpread: (
                { name: { value: name } }: any,
                // ts complains about these not being used, however they're positional
                // parameters, so we can't remove them due to ancestors being needed.
                // @ts-ignore
                key: any,
                parent: any,
                path: any,
                ancestors: any,
            ) => {
                if (fragmentPersistPaths[name]) {
                    let fieldPath = ancestors
                        .filter(({ kind }: any) => kind === 'Field')
                        .map(({ name: { value: name } }: any) => name);

                    fieldPath = fieldPath.concat(fragmentPersistPaths[name]);

                    let fragment = name;
                    let parent = fragmentPaths[fragment][0];

                    while (parent && parent !== '$ROOT' && fragmentPaths[parent]) {
                        fieldPath = fragmentPaths[parent].slice(1).concat(fieldPath);
                        parent = fragmentPaths[parent][0];
                    }

                    paths.push(fieldPath);
                }
            },
        });
    }

    return { query, paths };
};

/**
 * Given a data result object path, return the equivalent query selection path.
 */
const toQueryPath = (path: any[]) => path.filter((key) => isNaN(Number(key))).join('.');

/**
 * Given a data result object, attach __persist values.
 */
const attachPersists = (paths: any[], object: any) => {
    const queryPaths = paths.map(toQueryPath);

    // eslint-disable-next-line
    return traverse(object).map(function () {
        if (
            !this.isRoot &&
            this.node &&
            typeof this.node === 'object' &&
            Object.keys(this.node).length &&
            !Array.isArray(this.node)
        ) {
            const path = toQueryPath(this.path);

            this.update({
                __persist: Boolean(
                    queryPaths.find((queryPath) => queryPath.indexOf(path) === 0 || path.indexOf(queryPath) === 0),
                ),
                ...this.node,
            });
        }
    });
};

export class PersistLink extends ApolloLink {
    public directive: string = 'persist';

    /**
     * Link query requester.
     */
    request = (operation: any, forward: any) => {
        const { query, paths } = extractPersistDirectivePaths(operation.query, this.directive);
        // Replace query with one without @persist directives.
        operation.query = query;

        // Remove requesting __persist fields.
        operation.query = visit(operation.query, {
            Field: ({ name: { value: name } }: any): any => {
                if (name === '__persist') {
                    return null;
                }
            },
        });

        return forward(operation).map((result: any) => {
            if (result.data) {
                result.data = attachPersists(paths, result.data);
            }

            return result;
        });
    };
}

/**
 * Filter the cache before saving it for persistence.
 *
 * Saves the result of any query decorated with @persist, or any items whose ID
 * is in the JSON-encoded 'cache-manifest' in localStorage.
 */
export async function persistenceMapper(data: any) {
    const parsed = JSON.parse(data);

    const mapped: any = {};
    const persistEntities: any[] = [];
    const rootQuery = parsed['ROOT_QUERY'] ?? {};

    const markedItems = parseCacheManifest(localStorage.getItem('cache-manifest') ?? '{}');
    // the `me` query should only be persisted so long as the user is logged in
    const isLoggedIn: boolean =
        JSON.parse(localStorage.getItem('global_context_store') ?? '{}')?.user?.isLoggedIn ?? false;

    // (re)build the root query object after filtering
    mapped['ROOT_QUERY'] = Object.entries<any>(rootQuery).reduce(
        (obj: any, [queryName, queryResult]) => {
            if (queryName === '__typename') return obj;

            if (/@persist$/.test(queryName) || (/^me$/.test(queryName) && isLoggedIn)) {
                obj[queryName] = queryResult;

                const entities = normalize(queryResult, parsed);
                persistEntities.push(...entities);
            }

            return obj;
        },
        { __typename: 'Query' },
    );

    // persist all location types so the autocomplete works offline
    const locationTypeIds = Object.entries(parsed)
        .filter(([_, item]: [string, any]) => item?.__typename === 'LocationType')
        .map(([id, _]) => id);

    // persist all manually marked items and their deps
    for (const key of [...Object.keys(markedItems), ...locationTypeIds]) {
        const entities = normalize(parsed[key], parsed);
        persistEntities.push(key, ...entities);
    }

    // copy over all the items to persist
    for (const key of new Set(persistEntities)) {
        mapped[key] = parsed[key];
    }

    return JSON.stringify(mapped);
}

/**
 * Traverse the given object and collect all cache references used.
 *
 * The Apollo cache stores everything as a flat list, so any subitems are
 * replaced with a ref to the main object at the root of the cache. This
 * function recursively parses the given object and returns a flat list of all
 * cache items referenced at any layer in the object.
 *
 * The cache_root should be specified so that we can look up any found keys and
 * further recurse into them, to ensure we have all the necessary nested data
 * available.
 *
 * The `found` argument is modified in-place and passed to any recursive calls,
 * which makes it easier for us to keep track of what refs have already been
 * found.
 *
 * @returns string[] A list of refs used inside the input (data) object
 */
export function normalize(
    obj: any,
    cache_root: { [key: string]: any } = {},
    found: Array<string> = [],
    depth: number = 0,
) {
    const isNormalObject = (obj: any) => typeof obj === 'object' && obj !== null;

    // do not recurse into locations, unless this is the top-level item being saved.
    if (obj && obj.__typename === 'Location' && depth !== 0) {
        return [];
    }

    // refs are represented by an object with a single __ref property
    const key = obj?.__ref;
    // is this an ref, and one we haven't already come across?
    // We only handle identical refs once to avoid infinite loops.
    if (key && !found.includes(key)) {
        // it's a good idea to push the current item before actually recursing into it
        found.push(key);
        const entity = cache_root[key] ?? null;
        if (entity) {
            normalize(entity, cache_root, found, depth + 1);
        }
        // recurse into containers
    } else if (Array.isArray(obj) || isNormalObject(obj)) {
        // check deps of all items in the array if an array, or all the properties if an object
        const items = isNormalObject(obj) ? Object.values<any>(obj) : obj;
        items.map((item: any) => normalize(item, cache_root, found, depth + 1)).flat();
    }

    return [...new Set(found)].filter((id) => id !== null);
}

export default PersistLink;
