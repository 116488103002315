import { createContext, Dispatch, PropsWithChildren, useEffect, useReducer } from 'react';
import { GlobalReducer } from '../reducers/GlobalReducer';
import { initialPreferenceState, PreferenceState } from '../reducers/PreferenceReducer';
import { initialUserState, UserState } from '../reducers/AuthReducer';

interface GlobalContextState {
    user: UserState;
    preferences: PreferenceState;
}

const globalContextBlankState: GlobalContextState = {
    user: initialUserState,
    preferences: initialPreferenceState,
};

const defaultGlobalContextValue: [GlobalContextState, Dispatch<any>] = [globalContextBlankState, () => {}];

export const GlobalContext = createContext(defaultGlobalContextValue);

export function GlobalContextProvider(props: PropsWithChildren<{}>) {
    const valueFromJson = localStorage.getItem('global_context_store');

    const [state, dispatch] = useReducer(
        GlobalReducer,
        valueFromJson ? JSON.parse(valueFromJson) : globalContextBlankState,
    );

    useEffect(() => {
        localStorage.setItem('global_context_store', JSON.stringify(state));
    }, [state]);

    return <GlobalContext.Provider value={[state, dispatch]}>{props.children}</GlobalContext.Provider>;
}
